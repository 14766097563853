import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%'
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            Agende sua&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Visita
            </Typography>
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
            Transforme seu ambiente com segurança e qualidade. Conte com a expertise da Tenalli para soluções elétricas que iluminam seu lar e impulsionam seu negócio. <br/>Entre em contato agora mesmo!
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            {/* <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Número com DDD"
              placeholder="Número com DDD"
              inputProps={{
                autocomplete: 'off',
                ariaLabel: 'Número com DDD',
              }}
            /> */}
            <Button variant="contained" color="primary" onClick={() => window.location.href='https://wa.me/555399907347'}>
              Entrar em contato
            </Button>
          </Stack>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            Ao clicar em &quot;Entrar em contato&quot; será redirecionada(o) para o aplicativo &nbsp;
            <Link href="#" color="primary">
              WhatsApp
            </Link>
             .
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}