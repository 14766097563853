import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import servicos_1 from '../images/servicos/instalacao.png'
import servicos_2 from '../images/servicos/manutencao.png'
import servicos_3 from '../images/servicos/solar.png'
import servicos_4 from '../images/servicos/eficiencia.png'
import { Grid } from '@mui/material';
import dadosServico from '../json/servicos.json'
import ScrollDialog from './ServicosPopUp'

const images = {
  "../images/servicos/instalacao.png": servicos_1,
  "../images/servicos/manutencao.png": servicos_2,
  "../images/servicos/solar.png": servicos_3,
  "../images/servicos/eficiencia.png": servicos_4,
};

function ServicosCard({servico}) {
  return (
    <Card sx={{ maxWidth: 300, display: 'flex', flexDirection: 'column', height:'100%' }}>
      <CardMedia
        component="img"
        alt="Serviço Elétrico"
        height="140"
        image={images[servico.img]}
      />
      <CardContent style={{height:'100%'}}>
        <Typography gutterBottom variant="h5" component="div">
          {servico.nome}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {servico.descricao}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Contato</Button>
        <ScrollDialog></ScrollDialog>
      </CardActions>
    </Card>
  );
}

function ServicosGroup({servicos}){

  const rows = []

  console.log(servicos)

  servicos.servicos_eletricos.forEach(servico => {
    
    rows.push(

      <Grid item><ServicosCard servico={servico}></ServicosCard></Grid> 

    );
    
  });

  return rows

}

export default function FixedContainer() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xlg">
      <Typography variant="h2" sx={{margin:{xs: "0px 10px 60px 10px"}}}>Nossos Serviços</Typography> {/* Título */}
        <Box component="section" sx={styles.nossosServicos}>
        <Grid container spacing={2} justifyContent={'center'}>
          <ServicosGroup servicos={dadosServico}></ServicosGroup>
        </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}

const styles = {
  nossosServicos: {
    padding: '4',
    display: 'inline-flex',
  }
}