import React from 'react'
import HeaderTwo from './components/HeaderTwo'
import Footer from './components/Footer'
import { Container, Grid, Typography, Avatar, Box, Link, CardMedia, CardContent, Card, Paper } from '@mui/material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PreviewIcon from '@mui/icons-material/Preview';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import imagemQuemSomos from './images/servicos/servicos_1.png'

const QuemSomos = () => {
    const teamMembers = [
        {
            name: 'Nome 1',
            description: 'Descrição do membro da equipe 1...',
            fun:"Sócio",
            socialMedia: {
                twitter: 'https://twitter.com/username1',
                linkedin: 'https://linkedin.com/in/username1'
            }
        },
        {
            name: 'Nome 2',
            description: 'Descrição do membro da equipe 2...',
            fun:"Sócio",
            socialMedia: {
                twitter: 'https://twitter.com/username2',
                linkedin: 'https://linkedin.com/in/username2'
            }
        },
        // Add more team members as needed
    ];
    return (
        <div>
            <Container maxWidth="lg">
                <HeaderTwo/>
                {/* <Box  
                height="100vh" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
                sx={{
                    flexDirection: { xs: "column", md: "row" },
                    mt:{xs:"50px"}
                }}
                
                >
                    <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignSelf: 'center',
                        textAlign: 'center',
                        }}
                    >
                     Em breve!   
                    </Typography>
                </Box> */}
                   <Container sx={{ mt: 12 }}> {/* marginTop: 100px */}
            <Grid container spacing={4} sx={{textAlign:'justify'}}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" component="h1" sx={{mb:"20px", textAlign:'center'}}><EmojiPeopleIcon sx={{fontSize:'40px'}}></EmojiPeopleIcon>Quem somos</Typography>
                    <Typography variant="body1" paragraph  sx={{textIndent:'30px'}}>
                    A equipe qualificada da Tenalli é especializada em serviços elétricos de todos os tipos, oferecendo soluções confiáveis e inovadoras para nossos clientes. Com profissionais experientes e dedicados, estamos comprometidos em fornecer serviços de alta qualidade, mantendo padrões de segurança e eficiência.


                    </Typography>
                    <Typography variant="body1" paragraph sx={{textIndent:'30px'}}>
                    Nosso time é formado por técnicos e engenheiros capacitados, que possuem amplo conhecimento nas mais recentes tecnologias do setor elétrico. Combinando experiência e expertise, garantimos resultados excepcionais em instalações, manutenções e projetos elétricos, atendendo às necessidades específicas de cada projeto com excelência e profissionalismo.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{alignContent:'center'}}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt="Tenalli"
                            height="300"
                            image={imagemQuemSomos}
                            title="Tenalli"
                        />
                    </Card>
                </Grid>
            </Grid>

            <Grid container spacing={4} mt={4} sx={{textAlign:'justify', textIndent:'30px'}}>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{padding:'15px 0px 15px 0px'}}>
                        <Box sx={{margin:'0px 30px 0px 30px'}}>
                            <Typography variant="h4" sx={{mb:"15px", textAlign:'center'}} ><ChecklistIcon sx={{fontSize:'30px', marginRight:'10px'}}></ChecklistIcon>Missão</Typography>
                            <Typography variant="body1" >
                            Garantir segurança e excelência em serviços elétricos, superando as expectativas dos clientes com soluções inovadoras e sustentáveis, contribuindo para o desenvolvimento seguro e eficiente das comunidades.
                            </Typography>
                        </Box>
                    </Paper>
                    
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{padding:'15px 0px 15px 0px'}}>
                        <Box sx={{margin:'0px 30px 0px 30px'}}>
                            <Typography variant="h4" sx={{mb:"15px", textAlign:'center'}}><PreviewIcon sx={{fontSize:'30px', marginRight:'10px'}}></PreviewIcon>Visão</Typography>
                            <Typography variant="body1">
                            Ser referência nacional em serviços elétricos, reconhecida pela qualidade, compromisso com a segurança e pela constante busca por tecnologias sustentáveis, promovendo um impacto positivo na sociedade.
                            </Typography>
                        </Box>
                    </Paper>
                    
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{padding:'15px 0px 15px 0px'}}>
                        <Box sx={{margin:'0px 30px 0px 30px'}}>
                                <Typography variant="h4" sx={{mb:"15px", textAlign:'center'}}><GppMaybeIcon sx={{fontSize:'30px', marginRight:'10px'}}></GppMaybeIcon>Valores</Typography>
                                <Typography variant="body1">
                                Comprometimento com a segurança, qualidade, ética nas relações, inovação constante, valorização e capacitação dos colaboradores e responsabilidade socioambiental.
                                </Typography>
                        </Box>
                    </Paper>

                    
                </Grid>
            </Grid>

            <Box mt={8}>
                <Typography variant="h4" component="h2" sx={{textAlign:'center'}}>Nossa Equipe</Typography>
                <Grid container spacing={4} mt={2}>
                    {teamMembers.map((member, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card>
                                <CardContent>
                                    <Avatar alt={member.name} />
                                    <Typography variant="h6">{member.name}</Typography>
                                    <Typography variant="subtitle2" sx={{mb:"5px"}}>{member.fun}</Typography>
                                    <Typography variant="body2">{member.description}</Typography>
                                    <Box mt={1}>
                                        {member.socialMedia.twitter && (
                                            <Link href={member.socialMedia.twitter} target="_blank" rel="noopener" variant="body2" pr={1}>
                                                Twitter
                                            </Link>
                                        )}
                                        {member.socialMedia.linkedin && (
                                            <Link href={member.socialMedia.linkedin} target="_blank" rel="noopener" variant="body2" pr={1}>
                                                LinkedIn
                                            </Link>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
                <Footer/>
            </Container>
        </div>
    )
}

export default QuemSomos