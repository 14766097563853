import React from "react";
import Home from "./Home";
import Contato  from "./Contato";
import Servicos from "./Servicos";
import QuemSomos from "./Quemsomos"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const AppRoutes = () => {

    return (

        <Router>
            <Routes>
                <Route path="/" element={ <Home/>}></Route>
                <Route path="/contato" element={<Contato/>}></Route>
                <Route path="/servicos" element={ <Servicos/> }></Route>
                <Route path="/quem-somos" element={ <QuemSomos/> }></Route>
            </Routes>
        </Router>
    )
}

export default AppRoutes