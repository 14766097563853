
import React from 'react'
import HeaderTwo from './components/HeaderTwo'
import { Container } from '@mui/material'
import Box from '@mui/material/Box';
import Footer from './components/Footer'
import Typography from '@mui/material/Typography';

const Servicos = () => {
    return (
        <div>
            <Container maxWidth="lg">
                <HeaderTwo/>
                <Box  
                height="100vh" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
                sx={{
                    flexDirection: { xs: "column", md: "row" },
                    mt:{xs:"50px"}
                }}
                
                >
                    <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignSelf: 'center',
                        textAlign: 'center',
                        }}
                    >
                     Em breve!   
                    </Typography>
                </Box>
                <Footer/>
            </Container>
        </div>
    )
}

export default Servicos