
import './App.css';
import AppRoutes from './Routes'
import { ThemeProvider, createTheme} from "@mui/material/styles";

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#041e41',
    },
    secondary: {
      main: '#6900ff',
    },
    background: {
      default: '#efece5',
    },
  },
};

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#041e41',
    },
    secondary: {
      main: '#6900ff',
    },
    background: {
      default: '#efece5',
    },
  }
});


function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <AppRoutes></AppRoutes>
      </ThemeProvider>
    </div>
  );
}

export default App;
