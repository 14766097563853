import HeaderTwo from './components/HeaderTwo'
import WppTelaInicial from './components/WppTelaInicial'
import Servicos from './components/Servicos'
// import QuemSomos from './components/QuemSomos'
// import Localizacao from './components/Localizacao'
import Footer from './components/Footer'
import './App.css';
import { Container } from '@mui/material'



const Home = () => {

    return(
      <div className="App">
        <Container maxWidth="lg">
          <HeaderTwo/>
          <WppTelaInicial></WppTelaInicial>
          <Servicos/>
          {/* <QuemSomos/>
          <Localizacao/> */}
        </Container>
        <Footer/>
      </div>

    )
}

export default Home