import React from 'react'
import HeaderTwo from './components/HeaderTwo'
import { Container } from '@mui/material'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Footer from './components/Footer'
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const contatos = [
    <Button key="WhatsApp" href='https://wa.me/555399907347'> <WhatsAppIcon /> WhatsApp</Button>,
    <Button key="Instagram" href='https://www.instagram.com/tenalli.eng/'> <InstagramIcon /> Instagram</Button>,
    <Button key="Endereço" href='https://maps.app.goo.gl/ZcTu46TgrUvR792Q7'> <LocationOnIcon /> Endereço</Button>,
    <Button key="Facebook" href='https://web.facebook.com/tenalli.engenharia'> <FacebookIcon /> Facebook</Button>,
    <Button key="LinkedIn" href='https://www.linkedin.com/company/tenalli-engenharia/'> <LinkedInIcon /> LinkedIn</Button>,
    
  ];

const Contato = () => {
    return (
        <div>
            <Container maxWidth="lg">
                <HeaderTwo/>
                <Box  
                height="100vh" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
                sx={{
                    flexDirection: { xs: "column", md: "row" },
                    mt:{xs:"50px"}
                }}
                
                >
                    <Box 
                    width={"100%"} 
                    display={'flex'} 
                    flexDirection={"column"} 
                    justifyContent={"Center"} 
                    alignItems={"Center"}
                    sx={{
                        margin:{xs: "100px 10px 60px 10px"}

                    }}
                    >
                    <ButtonGroup orientation="vertical" aria-label="Vertical button group" fullWidth>
                        {contatos}
                    </ButtonGroup>
                    </Box>
                    <Box 
                    display={'flex'} 
                    flexDirection={"column"} 
                    justifyContent={"Center"} 
                    alignItems={"Center"} 
                    width={"100%"}
                    sx={{
                        padding:{xs: "0px 15px 0px 15px"}
                    }}
                    >
                    <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignSelf: 'center',
                        textAlign: 'center',
                        }}
                    >
                        Horário de &nbsp;
                        <Typography
                        component="span"
                        variant="h4"
                        sx={{
                            color: (theme) =>
                            theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}
                        >
                        Atendimento
                        </Typography>
                    </Typography>
                    <Typography variant="body1" textAlign="center" color="text.secondary">
                        De segunda a Sexta, das 8:00 às 18:00<br></br>
                        Aos Sábados das 8:00 às 13:00
                    </Typography>
                    <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignSelf: 'center',
                        textAlign: 'center',
                        marginTop: '20px'
                        }}
                    >
                        Contato
                    </Typography>
                    <Typography variant="body1" textAlign="center" color="text.secondary">
                        Entre em contato através do número: (53) 9 9990-7347<br></br>
                        Ou através das nossas redes sociais.
                    </Typography>
                    <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignSelf: 'center',
                        textAlign: 'center',
                        marginTop: '20px'
                        }}
                    >
                        Endereço
                    </Typography>
                    <Typography variant="body1" textAlign="center" color="text.secondary">
                        CEP: 96400420<br></br>
                        Rua Coronel José Otávio, nº384<br></br>
                        Centro, Bagé - RS<br></br>
                    </Typography>
                    </Box>
                </Box>
                <Footer/>
            </Container>
        </div>
    )
}

export default Contato